import { useEffect } from "react";

const Skills = () => {
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if(entry.isIntersecting){
                entry.target.classList.add('show');
              }
            });
          });
        
    })
  return (
    <section className="skills" id="skills">
                <h2 className='section-header'>Software and Tools</h2>
      <div className="vertical-line"></div>
                <p className="subheader">In my daily work, I make extensive use of a variety of software and tools designed for effective data analysis and business process management</p>
        <div className='container'>
            <div className='section'>
                <i className="fa-brands fa-microsoft"></i>
                <h3>MS Office Suite</h3>
                <p>The foundation of my workflow, used for a variety of tasks from data management to the production of reports.</p>
            </div>
            <div className='section'>
                <i className="fa-brands fa-microsoft"></i>
                <h3>MS Power Platform</h3>
                <p>I employ Power BI for advanced data visualisation, Power Automate for streamlining and automating tasks, and PowerApps for building custom business applications.</p>
            </div>
            <div className='section'>
                <i className="fa-solid fa-database"></i>
                <h3>Oracle Database and SQL Server Management Studio</h3>
                <p>These robust systems are where I delve into complex data analysis, managing, and querying databases to extract valuable insights.</p>
            </div>
            <div className='section'>
                <i className="fa-brands fa-python"></i>
                <h3>Python</h3>
                <p>A versatile language that I use for data input, automation and processing large amounts of files to get them ready for upload to the database.</p>
            </div>
        </div>
      
    </section>
  )
}

export default Skills
