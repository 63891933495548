import { useEffect } from 'react';
import {Link} from 'react-scroll';

const Hero = () => {

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if(entry.isIntersecting){
                entry.target.classList.add('show');
              }
            });
          });
        
          const hiddenElements = document.getElementsByClassName('hidden');
          for (let i = 0; i < hiddenElements.length; i++) {
            observer.observe(hiddenElements[i])
          }
    })


  return (
    <section className="hero" id="hero">
      

        <div className='inner-container'>
            {/* <div className="tk-blob tk-blob-g" style={{fill: "#e0dede", margin:"0px", position:"absolute", top:"7%",left:"7%", zIndex:"10"}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 274 303.2">
                    <path d="M260.2 41.4c20 29.2 14.6 74.5 7.2 124.4-7.3 49.9-16.6 104.5-49.2 126-32.5 21.6-88.4 10.2-132-15.2s-75-64.7-83.6-107.8C-6.1 125.7 8 79 36.3 47.8 64.5 16.7 107 1.3 150.9.1c43.9-1.1 89.3 12 109.3 41.3z"></path>
                </svg>
            </div>
            <div className="tk-blob tk-blob-b" style={{fill: "rgba(46,123,187,0.8)", margin:"0px", position:"absolute", top:"10%",left:"10%", zIndex:'11'}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 274 303.2">
                    <path d="M260.2 41.4c20 29.2 14.6 74.5 7.2 124.4-7.3 49.9-16.6 104.5-49.2 126-32.5 21.6-88.4 10.2-132-15.2s-75-64.7-83.6-107.8C-6.1 125.7 8 79 36.3 47.8 64.5 16.7 107 1.3 150.9.1c43.9-1.1 89.3 12 109.3 41.3z"></path>
                </svg>
            </div> */}

            <div className='hero-content'>
              <div className="container">
                <div className="text">
                  <h2>Transforming Data into Dynamic Business Processes</h2>
                  <p>Hello, I'm Valentina Deed, a specialised Data Analyst. I'm passionate about creating efficient business processes to simplify data reporting and uncover key insights. I thrive on detail-oriented and analytical work, and I'm constantly on the lookout for learning opportunities to further my skills.</p>
                  <Link to="about" spy={true} smooth={true} duration={700}>Learn More</Link>
                </div>
                <div className="img">
                  <img src="img/home-banner.svg"></img>
                </div>
              </div>
            </div>
        </div>
        


    </section>
  )
}

export default Hero
