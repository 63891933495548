import { useEffect } from 'react'

const About = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    const hiddenElements = document.getElementsByClassName('hidden');
    for (let i = 0; i < hiddenElements.length; i++) {
      observer.observe(hiddenElements[i])
    }
  })
  return (
    <section className='about' id="about">
      <h2 className='section-header'>About Me</h2>
      <div className="vertical-line"></div>

      <div className='container'>
        <div className='section'>
          <p>I am highly motivated, meticulously organised, and thrive on the analytical and detail-oriented nature of my work. Continually seeking to expand my knowledge and skill set, I am not just proficient in my work, but enthusiastic about each opportunity to learn more.</p>
        </div>
        <div className='section'>
          <p>Every day, I use SQL to explore intricate databases and unearth valuable insights, mainly through Oracle Database and SQL Server Management Studio. Excel is my go-to tool for data manipulation, presentation, and validation, while Power BI allows me to transform raw data into compelling stories that fuel strategic decision-making.</p>
          
        </div>
        <div className='section'>
          <p>In every task I take on, my aim is always to refine and perfect. I believe there's always room for improvement, so I'm consistently looking for ways to enhance the processes I work with. I'm also a lifelong learner, continuously developing new skills and enhancing my existing ones to stay ahead in this dynamic field of data analysis.</p>
        </div>
      </div>
    </section>
  )
}

export default About
