import React, { useState, useEffect } from 'react'
import Project from './Project'

const Portfolio = () => {
    const [projects, setProjects] = useState(
        [
            {
                id: 0,
                name: "Python: thesis project",
                desc: "I wrote this Python code in 2021 for my master’s thesis. The code uses 2 Google Cloud APIs to check whether my proposed STT-TTS method can eliminate background noise with a low word recognition error rate.  The code loops through noisy audio files from a speech database to perform speech synthesis and recognition. It outputs an audio file that contains speech without any background noise.",
                image: "./img/project1.png",
                link: 'https://drive.google.com/file/d/1t5DSBZ7ApxAad6e5M2wxv0_jUZxmOJtg/view?usp=sharing'
            },
            {
                id: 1,
                name: "R: Bellabeat project",
                desc: "The Bellabeat Capstone Project was completed as part of the last chapter of the Google Data Analytics Certificate. The project is hosted on Kaggle and written in RMarkdown and displayed in HTML. In this project, I cleaned, processed and analyzed different metrics of FitBit data to gain insights on user activity and give suggestions to improve Bellabeat’s marketing strategy.",
                image: "./img/project3.png",
                link: 'https://www.kaggle.com/code/valkyval/bellabeat-capstone-project'
            },

        ]
    )

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                }
            });
        });

        const hiddenElements = document.getElementsByClassName('hidden');
        for (let i = 0; i < hiddenElements.length; i++) {
            observer.observe(hiddenElements[i])
        }
    })

    return (
        <section className='portfolio' id="portfolio">
            <h2 className='section-header'>Latest Projects</h2>
            <div className="vertical-line"></div>
            <div className='container'>
                <div className='portfolio-inner'>
                    {projects.map((project) => (
                        <Project key={project.id} name={project.name} image={project.image} desc={project.desc} link={project.link} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Portfolio
