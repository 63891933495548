import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';

const Contact = () => {
    const formd = useRef(null)

    const handleFocus = (event) => {
        const labelEl = event.target.previousElementSibling;
        labelEl.classList.add("active");
    };

    const handleBlur = (event) => {
        if (event.target.value === "") {
            const labelEl = event.target.previousElementSibling;
            labelEl.classList.remove("active");
        }
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [message, setMessage] = useState({
        content: ''
    });

    const handleChange = event => {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value
        });
    };

    const handleSubmit = event => {
        event.preventDefault();

        emailjs.sendForm('service_9avo9jl', 'template_miwrt75', formd.current, 'zzwGLmKyydSfDkTlt')
        .then((result) => {
            setFormData({
                name: '',
                email: '',
                message: ''
              });
            setMessage({
                content: 'Your message has been sent!'
            });
        }, (error) => {
            setMessage({
                content: 'There was an issue sending your message, please try again later.'
            });
        });

    };


    return (
        <section className='contact' id="contact">
        <h2 className='section-header'>Contact Me</h2>
        <div className="vertical-line"></div>

            <div className="container">

                <form action="/" method="POST" className="cform-control" ref={formd} onSubmit={handleSubmit}>

                    <div className="row">
                        <div className="form-item">
                            <label htmlFor="name">Your Name*</label>
                            <input type="text" name="name" className="acinput" onFocus={handleFocus} onBlur={handleBlur} value={formData.name} onChange={handleChange} required />

                        </div>
                        <div className="form-item">
                            <label htmlFor="email">Email Address*</label>
                            <input type="email" name="email" className="acinput" onFocus={handleFocus} onBlur={handleBlur} value={formData.email} onChange={handleChange} required />


                        </div>
                    </div>
                    <div className="col">
                        <div className="form-item">
                            <label htmlFor="message">Message*</label>
                            <textarea name="message" id="" cols="30" rows="10" onFocus={handleFocus} onBlur={handleBlur} value={formData.message} onChange={handleChange} required></textarea>


                        </div>


                        <button className="button">Send Message</button>
                        
                        <span className={message.content !== '' ? 'contact-message active' : 'contact-message'}>
                            {message.content}
                        </span>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Contact