import React, { useState } from "react";
import { Link } from 'react-scroll';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (

        <header>
            <div className="container">
                <div className='brand'>
                    <Link spy={true} smooth={true} duration={700} to="hero"><span>Valentina Deed</span></Link>
                </div>
                <span className="nav-toggle">
                    <i className="fa fa-bars" onClick={() => setIsOpen(!isOpen)}></i>
                </span>

                <nav className={`main-nav ${isOpen ? 'active' : ''}`} id="mainNav">
                    <span className="nav-toggle-close">
                        <i className="fa fa-xmark" onClick={() => setIsOpen(!isOpen)}></i>
                    </span>
                    <ul>
                        <li><Link to="about" spy={true} smooth={true} duration={700} onClick={() => setIsOpen(false)}>About</Link></li>
                        <li><Link to="skills" spy={true} smooth={true} duration={700} onClick={() => setIsOpen(false)}>Skills</Link></li>
                        <li><Link to="portfolio" spy={true} smooth={true} duration={700} onClick={() => setIsOpen(false)}>Portfolio</Link></li>
                        <li className="last"><Link to="contact" spy={true} smooth={true} duration={700} onClick={() => setIsOpen(false)}>Contact</Link></li>
                    </ul>
                </nav>
            </div>
        </header>


    );
}

export default Header
