import React, { useState } from 'react'

const Project = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    

    return (
        <>
        <div className="project" onClick={() => setIsOpen(!isOpen)}>
            <div className="section">
                <img src={props.image}></img>
            </div>
            <div className="section">
                <h3>{props.name}</h3>
                <p>{props.desc}</p>
                
                <a href={props.link} className="btn" target="_blank">View Project</a>
            </div>
        </div>
        
        </>
    )
}

export default Project
